.modalBox {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 90%;
    background: url('../../../assets/texture_background.png');
    background-color: #f5f5f7;
    padding: 20px 50px;
    border-radius: 0;
    border: none;
    direction: rtl !important;
}
.modalBox:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #4a54a0;
    width: 10px;
    height: 40%;
    border-radius: 0 8px 8px 0;
    transform: translateY(-50%);
}
.modalHeader {
    margin-bottom: 15px;
    padding-bottom: 15px;
}
.modalHeader p {
    text-align: center;
    font-weight: bold;
    margin: 0 !important;
}
.modalBody {
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 30px;
    text-align: right !important;
}
.modalFooter {
    margin-top: 15px;

}
.infoItemWrapper {
    position: relative;
    margin-top: 10px;
}
.infoItemWrapper textarea {
    height: 6em;
}
.removeBtnWrapper {
    position: absolute;
    top: -5px;
    left: -30px;
}
.radioWrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
}
.radioGroup_A {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
}
.radioGroup_B {
    display: flex;
    flex-direction: column;

}
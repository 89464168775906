.descriptsCloud {
    position: absolute;
    top: 0;
    right: 50%;
    width: 80%;
    height: 100% !important;
    min-height: 100% !important;
    margin: 0 auto;
    padding: 25px 10px 15px 10px;
    font-weight: normal !important;
    background-color: white;
    border-radius: 15px;
    transform: translateX(50%);
    transition: all .2s;
    z-index: 99;
}
.descriptsCloudInner {
    display: grid;
    height: 80%;
    overflow-y: auto !important;
    overflow-x: visible !important;
}

.descriptsCloudForm {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px 10px !important;
    background-color: #ffffff !important;
}
.buttonWrapper {
    position: absolute;
    bottom: 10px;
    right: 50%;
    transform: translateX(50%);
    z-index: 99;
}
.cloudComment {
    position: absolute;
    top: -20px;
    display: flex;
    align-items: center;
    margin-right: 15px;
}
@media only screen and (max-width: 1000px), (max-height: 650px) { /* Small Monitors */
    .descriptsCloud {
        height: 100% !important;
        min-height: 100% !important;
    }
    .descriptsCloudInner {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .descriptsCloudInner {
        display: -ms-flexbox;
        flex-wrap: wrap;
        align-content: start !important;
        -ms-align-content: start !important;
    }
}
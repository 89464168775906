.hiddenChainWrapper {
    display: flex;
    flex-direction: column;
    width: 24.6%;
    min-width: 24.6%;
    min-height: 300px;
    margin: 8px 3px;
    padding: 5px;
    border-bottom: 3px solid #d8d8d8;
    border-right: 2px solid #d8d8d8;
    background-color: #f5f5f7;
    page-break-after: always;
}
.hiddenChainItems {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-items: flex-start;
    justify-content: flex-start;
}
.hiddenChainItemWrapper {
    width: 100%;
    padding: 10px 0;
}
.hiddenChainItemContainer {
    position: relative;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 10px 20px;
    border-top: 0.5px solid #e8e8e8;
    text-align: center;
}
.contentGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto 1fr 1fr auto auto;
    grid-template-areas: 
    "itemA itemD"
    "itemA itemE"
    "itemA itemF"
    "itemA itemF" 
    "itemB itemF"
    "itemB itemF";
    grid-gap: 5px;
    height: 100%;
}
.gridItem {
    padding: 5px 10px;
}
.gridItem_A {
    grid-area: itemA;
    display: flex;
    flex-direction: column;
}
.gridItem_B {
    grid-area: itemB;
    display: flex;
    flex-direction: column;
    min-height: 100px;
}
.gridItem_D {
    grid-area: itemD;
}
.gridItem_E {
    grid-area: itemE;
}
.gridItem_F {
    grid-area: itemF;
    display: flex;
    flex-direction: column;
}

.missingItemsList {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    margin: 0;
    padding-bottom: 15px;
    height: auto;
    width: 95%;
    max-height: 100%;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition: max-height .2s;
}
.missinItemPopOver {
    direction: rtl !important;
    text-align: right;
    border: none;
    box-shadow: 0px 0px 5px rgb(186, 186, 186);
    min-width: 350px;
}
.textarea_F {
    height: 33.33%;
    min-height: 33.33%;
    display: flex;
}

.hiddenChainsTitle {
    margin: 10px 3px;
    padding: 5px 10px;
    border-bottom: 3px solid #d8d8d8;
    border-right: 2px solid #d8d8d8;
    background-color: #f5f5f7;
}

@media only screen and (min-width: 1800px) { /* Extra Large Monitors */
    .gridItem_B {
        min-height: 200px;
    }
}
@media only screen and (max-width: 1200px) { /* Medium Monitors */
    .summaryInnerGrid {
      display: grid !important;
      grid-template-columns: 1fr 10% !important;
    }
    .summaryMainContent {
        padding: 0 50px !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .contentGrid {
        display: grid;
        display: -ms-grid;
        -ms-grid-columns: 1fr 1fr;
        -ms-grid-rows: 1fr 1fr 2fr 2fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr ;
        height: 100%;
    }
    .gridItem_A {
        margin: 0 0 5px 5px;
        -ms-grid-row-span: 4;
        grid-row: 1;
        grid-column: 1;
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }
    .gridItem_B {
        margin-left: 5px;
        -ms-grid-row-span: 2;
        grid-row: 5;
        grid-column: 1;
        -ms-grid-row: 5;
        -ms-grid-column: 1;
    }
    .gridItem_D {
        margin-bottom: 5px;
        -ms-grid-row-span: 1;
        grid-row: 1;
        grid-column: 2;
        -ms-grid-row: 1;
        -ms-grid-column: 2;
    }
    .gridItem_E {
        margin-bottom: 5px;
        -ms-grid-row-span: 1;
        grid-row: 2;
        grid-column: 2;
        -ms-grid-row: 2;
        -ms-grid-column: 2;
    }
    .gridItem_F {
        -ms-grid-row-span: 4;
        grid-row: 3;
        grid-column: 2;
        -ms-grid-row: 3;
        -ms-grid-column: 2;
    }
    
}

.loginMain {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.pageWrapper {
    min-height: 550px !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    direction: ltr !important;
    text-align: left;
}

.uppearStrip {
    direction: rtl !important;
    display: flex;
}
.bottomStrip {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 900px;
    background-image: url('../../../../assets/background_1.png');
    background-size: 100% 100%;
}
.pageHeader {
    width: fit-content;
    text-align: center;
    margin: 0 auto;
}
.pageHeader h1 {
    font-size: 7vw;
    transition: all .2s;
}

.formInput {
    width: 100%;
    border-width: 1px;
    border-color: rgb(238, 240, 239);
    border-style: solid;
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    transition: all 0.2s;
}
.formInput::placeholder {
    opacity: 0.2;
}
.formInput:focus {
    outline: none;
    border-color: rgb(165, 165, 165);
}
.formLabel {
    margin-right: 10px;
}

.boxWrapper {
    width: 40%;
    min-width: 320px;
    max-width: 420px;
    height: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    color: #656565;
    direction: rtl;
    text-align: right;
    border-width: 2px;
    border-color: #82BB2D;
    border-style: solid;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.95);
    transition: all .2s;
}
.loginHeader {
    text-align: center;
    padding: 15px 0 5px 0;
}
.lineBottom {
    width: 100%;
    height: 1px;
    background-image: linear-gradient( to right, rgb(130,187,45) 17%, rgb(169,226,84) 100%);
    background-image: -moz-linear-gradient( to right, rgb(130,187,45) 17%, rgb(169,226,84) 100%);
    background-image: -webkit-linear-gradient( to right, rgb(130,187,45) 17%, rgb(169,226,84) 100%);
    background-image: -ms-linear-gradient( to right, rgb(130,187,45) 17%, rgb(169,226,84) 100%);
}
.loginForm {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 25px 15px;

}
.errorsMsg {
    position: absolute;
    top: -5px;
    right: 50%;
    width: 100%;
    height: 35px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
    transform: translateX(50%);

}
.loginFooter {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.greenBtn {
    width: 40%;
    padding: 5px 15px;
    color: white;
    border: none;
    border-radius: 14px;
    background-color: #82BB2D;
    box-shadow: 0px 5px 12px 0px rgba(124, 189, 26, 0.455);
    transition:  all 0.2s;
}
.greenBtn:hover {
    background-color: #87cb20;
}
.bottomFooter {
    flex-grow: 1;
    text-align: center;
}
.bottomFooter div {
    margin-top: 10px;
}

@media only screen and (max-width: 1000px) {

    .pageHeader {
        position: absolute !important;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
    }
    .pageHeader h1 {
        font-size: 8vw;
    }
    .boxWrapper {
        position: absolute !important;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
    }

}
.navbarWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 50px;
    border-bottom: 2px solid  rgba(231, 231, 231, 0.51);
    background-color: #ffffff;
    box-shadow: 0 0 2px rgba(231, 231, 231, 0.51);
    transition: all 0.2s;
    z-index: 623;
}
.navbarTitle {
    display: flex;
    align-items: center;
}
.navbarTitle div:first-child > img { cursor: pointer; transition: all 0.4s }
.navbarTitle div:first-child > img:hover { transform: scale(1.05) }
.shadowVertLeft {
    margin-right: 15px;
    opacity: 0.6;
}
.dynamicTitle {
    margin-right: 10px;
    transition: all 0.2s;
}
.menuBars {
    font-size: 2rem;
    margin-right: auto;
    transition: all 0.2s;
}

@media screen and (min-height: 1000px) {
    .navbarWrapper {
        padding: 20px 50px;
        border-bottom: 5px solid  rgba(231, 231, 231, 0.51);
    }
    .dynamicTitle {
        font-size: 2rem;
    }
    .menuBars {
        font-size: 2.5rem;
    }
}
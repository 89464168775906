.questFrameWrapper, .questFrameWrapper_unordered, .questFrameWrapper_info_item, .questFrameWrapper_question, .generalQuestFrame {
    position: relative;
    width: 100%;
    padding: 15px 30px;

}
.questFrameWrapper:before {
    content: attr(value);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: -12px;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    background-color: #4a54a0;
    box-shadow: 4px 3px 9px 0px rgba(75, 85, 161, 0.43);
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
.questFrameWrapper:after, .questFrameWrapper_unordered:after, .questFrameWrapper_info_item:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #4a54a0;
    width: 10px;
    height: 33.33%;
    border-radius: 0 8px 8px 0;
    transform: translateY(-50%);
}
.questFrameWrapper_info_item:before {
    content: 'פריט מידע ' attr(value);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: 10px;
    color: white;
    font-size: 1.2em;
    background-color: #82BB2D;
    box-shadow: 4px 1px 3px 0px rgba(75, 85, 161, 0.2);
    width: 160px;
    height: 25px;
    border-radius: 16px;
}
.questFrameWrapper_question:before {
    content: '';
    background: url('../../../assets/question_mark.png') no-repeat;
    background-size: contain;
    position: absolute;
    width: 40px;
    height: 80%;
    top: 50%;
    right: -25px;
    transform: translateY(-50%);
}
.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: -16px;
    color: white;
    background-color: #4a54a0;
    box-shadow: 4px 3px 9px 0px rgba(0, 0, 0, 0.22);
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

@media screen and (min-height: 1000px) and (min-width: 1800px) {
    .questFrameWrapper:before {
        width: 35px;
        height: 35px;
        right: -18px;
        font-size: 0.9vw;
    }
    .questFrameWrapper_info_item:before {
        width: 190px;
        height: 30px;
        top: -18px;
    }
}
.buttonsWrapper {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-template-rows: repeat(auto, 80px);
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1em;
}

@media only screen and (max-width: 1200px) {
    .buttonsWrapper {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(auto-fit, 110px);
        letter-spacing: 0.5px;
    }
}
@media only screen and (min-width: 1800px) {
    .buttonsWrapper {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, 220px);
        grid-template-rows: repeat(auto, 80px);
    }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .buttonsWrapper {
        display: -ms-flexbox;
        -ms-flex-wrap: wrap ;
        flex-wrap: wrap;
        -ms-flex-pack: start !important;
        justify-content: start !important;
    }
}
.checkboxBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    max-height: 50px;
    color: #656565;
    background-color: #ffffff;
    border-bottom: 3px solid #D8D8D8;
    border-radius: 0;

}
@media screen and (max-width: 800px) {
    .checkboxBtn {
        padding: 5px 0 !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .checkboxBtn {
        -ms-flex: 0 0 12%;
        flex: 0 0 12%;
        height: 40px;
        max-height: 40px;
        margin: 0 8px 8px 0 !important;
    }
}
.descriptsCloud {
    position: absolute;
    top: 0;
    right: 50%;
    width: 80%;
    height: 100%;
    min-height: 100%;
    margin: 0 auto;
    padding: 25px 10px 15px 10px;
    font-weight: normal !important;
    background-color: white;
    border-radius: 15px;
    transform: translateX(50%);
    transition: all .2s;
    z-index: 99;
}

.descriptsCloudForm {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px 10px !important;
    background-color: #ffffff !important;
}
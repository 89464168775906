@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');
/*! ===================================== App Classes ===================================== */

* {
  font-family: "Rubik", Arial ,sans-serif !important;
  -webkit-print-color-adjust: exact !important;
}
.App {
  height: 100vh;
  max-height: 100vh;
}
.App {
  direction: rtl;
  text-align: right;
}

.textGreen {
  color: #82bb2d !important;
}
.textWhite {
  color: #ffffff !important;
}
.bgGreen {
  background-color: #82bb2d !important;
}
.bgRedLight {
  background-color: #e84f0e50;
}
.textRed {
  color: #e84e0e !important;
}
.textBlue {
  color: #4a54a0 !important;
}
.textGray {
  color: #656565 !important; 
}
.bgBlue {
  background-color: rgb(74, 84, 160) !important;
}
.borderBlue1 {
  border: 1px solid #4a54a0 !important;
}
.borderBlue2 {
  border: 2px solid #4a54a0 !important;
}
.boxShadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.cursor-help {
  cursor: help !important;
}


/*! ===================================== General Style ===================================== */

.generalLink {
  cursor: pointer;
}
.generalBlueLink {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.2s;
}
.generalBlueLink:hover {
  color: #0095ff !important;
}
.outlineOff:focus {
  box-shadow: none;
  outline: none;
}
.dynamicShadow {
  background: 
    linear-gradient(white 30%, rgba(255,255,255,0)), 
    linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
    radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(50% 100%,farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background:
    linear-gradient(white 30%, rgba(255,255,255,0)),
    linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
.contentFade {
  -webkit-mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
}

.responsive-img {
  max-height: 100%;
  width: 100%;
  object-fit: contain;
}

.rulesTextarea {
  display: block;
  width: 100%;
  height: 100%;
  background-attachment: local;
  background-image: linear-gradient(
      to right,
      transparent 10px,
      transparent 10px
    ),
    linear-gradient(to left, transparent 10px, transparent 10px),
    repeating-linear-gradient(
      #f5f5f7,
      #f5f5f7 30px,
      #d0d0d0 31px, 
      #f5f5f7 32px
    );
  line-height: 31px;
  padding: 5px 10px;
  border: none;
}
.rulesTextarea:focus {
  outline: none;
}
.whiteRulesTextarea {
  display: block;
  width: 100%;
  height: 100%;
  background-attachment: local;
  background-image: linear-gradient(
      to right,
      transparent 10px,
      transparent 10px
  ),
    linear-gradient(to left, transparent 10px, transparent 10px),
    repeating-linear-gradient(
      #ffffff,
      #ffffff 30px,
      #dddddd 31px,
      #ffffff 32px
    );
  line-height: 31px;
  padding: 5px 10px;
  border: none;
}
.whiteRulesTextarea:focus {
  outline: none;
}
.title-tag {
  width: fit-content;
  max-width: fit-content;
  margin: 0 auto;
  align-self: center;
  color: white;
  padding: 5px 25px;
  border-radius: 0 0 25px 25px;
}

/* --- Custom scrollbar --- */

.scrollbar-off::-webkit-scrollbar { display: none; }
.scrollbar-off { -ms-overflow-style: none !important; scrollbar-width: none !important; } /* effects IE & Edge */

/* thin scrollbar */
.custom-scrollbar::-webkit-scrollbar  { 
  width: 7px;
  height: 7px;
  background-color: #4a54a0;
  border-radius: 25px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a54a0;
  border-radius: 25px;
}
.custom-scrollbar::-webkit-scrollbar-track-piece  { 
  border-radius: 25px;
  background-color: #D8D8D8;
}
.custom-scrollbar::-webkit-scrollbar-corner { 
  display: none;
}
/* thick scrollbar */
.thick-custom-scrollbar::-webkit-scrollbar  { 
  width: 12px;
  height: 12px;
  background-color: #4a54a0;
  border-radius: 25px;
}
.thick-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a54a0;
  border-radius: 25px;
}
.thick-custom-scrollbar::-webkit-scrollbar-track-piece  { 
  border-radius: 25px;
  background-color: #D8D8D8;
}
.thick-custom-scrollbar::-webkit-scrollbar-corner { 
  display: none;
}
/* thick square scrollbar */
.thick-square-custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #4a54a0;
}
.thick-square-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a54a0;
}
.thick-square-custom-scrollbar::-webkit-scrollbar-track-piece  {
  background-color: #D8D8D8;
}
.thick-square-custom-scrollbar::-webkit-scrollbar-corner { 
  display: none;
}

.colorFulToolTip, .baseColorToolTip {
  opacity: 0.95 !important;
  min-width: 250px !important;
  max-width: 40vw !important;
  max-height: 20vh !important;
  overflow: hidden;
}
.colorFulToolTip {
  text-align: center;
  background-color: #4a54a0 !important;
  box-shadow: 1px 2px 3px rgba(172, 172, 172, 0.2) !important;
}
.baseColorToolTip {
  box-shadow: 1px 2px 6px rgba(102, 102, 102, 0.5) !important;
  background-color: #f5f5f7 !important;
}
.baseColorToolTip:before, .baseColorToolTip:after {
  border: none !important;
}
.colorFulToolTip:before, .colorFulToolTip:after {
  border-color: #4a54a0 transparent transparent transparent !important;
}
.tooltip > .tooltip-inner {
  max-width: 100% !important;
  direction: rtl !important;
  background-color: white !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before { border-top-color: white }
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before { border-right-color: white }
.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before { border-bottom-color: white }
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before { border-left-color: white }

.confirm-overlay {
  z-index: 9999999999 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.confirm-dialog {
  position: relative;
  direction: rtl !important;
  text-align: right;
  padding: 30px;
  background: url('./assets/texture_background.png');
  background-color: #f5f5f7;
  padding: 20px 50px;
}
.confirm-dialog:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #4a54a0;
  width: 10px;
  height: 40%;
  border-radius: 0 8px 8px 0;
  transform: translateY(-50%);
}

/*** ----- Custom Radio buttons & Radio Switch ----- ***/
                                                      
.customRadioBtn {                                     
  display: inline-flex;                               
  align-items: center;                                
}                                                     
                                                      
.customRadioBtn input {                               
  display: none;                                      
}                                                     
                                                      
.customRadioBtn .circle {                             
  position: relative;                                 
  height: 14px;                                       
  width: 14px;                                        
  margin-left: 5px;                                   
  border: 1px solid #656565;                        
  background-image: linear-gradient(                  
    90deg,                                            
    rgb(212, 212, 212) 0%,                          
    rgb(255, 255, 255) 100%                         
  );                                                  
  background-image: -moz-linear-gradient(             
    90deg,                                            
    rgb(212, 212, 212) 0%,                          
    rgb(255, 255, 255) 100%                         
  );                                                  
  background-image: -webkit-linear-gradient(          
    90deg,                                            
    rgb(212, 212, 212) 0%,                          
    rgb(255, 255, 255) 100%                         
  );                                                  
  background-image: -ms-linear-gradient(              
    90deg,                                            
    rgb(212, 212, 212) 0%,                          
    rgb(255, 255, 255) 100%                         
  );                                                  
  border-radius: 50%;                                 
}                                                     
                                                      
.customRadioBtn .circle:after {                       
  content: "";                                        
  height: 7px;                                        
  width: 7px;                                         
  display: block;                                     
  position: absolute;                                 
  top: 50%;                                           
  left: 50%;                                          
  transform: translate(-50%, -50%);
  background: #4a54a0;                              
  border-radius: 50%;                                 
  opacity: 0;                                         
  transition: all 0.4s;                               
}                                                     
                                                      
.customRadioBtn input:checked ~ .circle:after {       
  opacity: 1;                                         
}                                                     
                                                      
/** Radio Switch **/                                  
                                                      
.customSwitchWrapper {                                
  display: inline-flex;                               
  align-items: center;                                
  margin-bottom: 5px;                                 
}                                                     
.customSwitchText {                                   
  margin-right: 5px;                                  
}                                                     
.customSwitchBtn {                                    
  position: relative;                                 
  width: 30px;                                        
  height: 11px;                                                       
}                                                       
.customSwitchBtn input {                                                       
  display: none;                                                       
}                                                       
.customSwitchBtn span {                                                       
  position: absolute;                                                       
  cursor: pointer;                                                       
  top: 0;                                                       
  left: 0;                                                       
  right: 0;                                                       
  bottom: 0;                                                       
  background: linear-gradient(                                                       
    to right,                                                       
    rgb(255, 255, 255) 0%,                                                       
    rgb(224, 224, 224) 100%                                                       
  );                                                       
  background: -moz-linear-gradient(                                                       
    to right,                                                       
    rgb(255, 255, 255) 0%,                                                       
    rgb(224, 224, 224) 100%                                                       
  );                                                       
  background: -webkit-linear-gradient(                                                       
    to right,                                                       
    rgb(255, 255, 255) 0%,                                                       
    rgb(224, 224, 224) 100%                                                       
  );                                                       
  background: -ms-linear-gradient(                                                       
    to right,                                                       
    rgb(255, 255, 255) 0%,                                                       
    rgb(224, 224, 224) 100%                                                       
  );                                                       
  border: 1px solid #656565;                                                       
  border-radius: 34px;                                                       
  -webkit-transition: 0.4s;                                                       
  transition: 0.4s;                                                       
}                                                       
.customSwitchBtn span:before {                                                       
  position: absolute;                                                       
  content: "";                                                       
  height: 16px;                                                       
  width: 16px;                                                       
  top: -3px;                                                       
  right: -3px;                                                       
  background-color: white;                                                       
  border: 1px solid #656565;                                                       
  border-radius: 50%;                                                       
  -webkit-transition: 0.4s;                                                       
  transition: 0.4s;                                                       
}                                                       
.customSwitchBtn input:checked + span {                                                       
  background: #4a54a0;                                                       
}                                                       
.customSwitchBtn input:checked + span:before {                                                       
  transform: translateX(-17px);                                                       
}
/*** ----- ^^ Custom Radio buttons & Radio Switch ^^ ----- ***/

/** Input Modal **/

.modalBox {
  direction: rtl;
  display: flex;
  flex-direction: column;
  background: url('./assets/texture_background.png');
  background-color: #f5f5f7;
  padding: 0px 50px 20px 50px;
  border-radius: 0;
  border: none;
}
.modalBox:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #4a54a0;
  width: 10px;
  height: 40%;
  border-radius: 0 8px 8px 0;
  transform: translateY(-50%);
}
.modalHeader {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.modalInput {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #d8d8d8;
  border-radius: 0; 
}
.modalInput:focus {
  background-color: transparent;
  border-bottom: 2px solid #a5a5a5;
}
.modalFooter {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

/*** ----- ^^ Input Modal ^^ ----- ***/
/*** ----- vv Quest Frame vv ----- ***/
.quest-frame, .quest-frame_p {
  margin-bottom: 2px;
  border-bottom: 3px solid #d8d8d8;
  border-right: 2px solid #d8d8d8;
  background-color: #f5f5f7;
}
.quest-frame_p {padding: 4mm !important}

/*** ----- ^^ Quest Frame ^^ ----- ***/

.searchToolButtons {
  border: none;
  background-color: transparent;
  transition: all 0.2s;
}
.searchToolButtons:hover {
  filter: brightness(130%);
  transform: scale(1.2);
}

/*! ===================================== Structure ===================================== */

.main-page-wrapper {
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: 60px auto 12px;
  background-image: url("./assets/texture_background.png");
  background-size: 100% 100%;
  margin: 0;
}
.inner-wrapper {
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-y: auto;
  -ms-overflow-x: hidden;
  padding-top: 30px;
}
.inner-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 12.5% 1fr auto;
}
.main-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
.main-content {
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  margin: 0 auto;
}
.bottom-content {
  height: 15%;
  display: flex;
}
.right-side-strip {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.left-side-strip {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

/*! ===================================== Layout ===================================== */

.hidden-chains-container {
  width: 100%;
  margin: 0 auto;
}
.navButtonsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 4rem;
  margin-bottom: 25px;
}

.attachToCenter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.attachToBottom {
  display: flex;
  align-items: flex-end;
}
.browseArrows {
  transition: all 0.2s;
}
.browseArrows:hover {
  transform: scale(1.3);
}
.browseArrows-inactive {
  color: rgb(184, 184, 184) !important;
}

.downer-line {
  background-image: linear-gradient(
    to right,
    rgb(75, 85, 161) 0%,
    rgb(130, 187, 45) 100%
  );
  background-image: -moz-linear-gradient(
    to right,
    rgb(75, 85, 161) 0%,
    rgb(130, 187, 45) 100%
  );
  background-image: -webkit-linear-gradient(
    to right,
    rgb(75, 85, 161) 0%,
    rgb(130, 187, 45) 100%
  );
  background-image: -ms-linear-gradient(
    to right,
    rgb(75, 85, 161) 0%,
    rgb(130, 187, 45) 100%
  );
  min-width: 100%;
  height: 12px;
}

.mainLogo {
  height: 60px;
  transition: all 0.2s;
}
.vector_1 { height: 30vh; transition: all .2s; }
.vector_2 { height: 30vh; transition: all .2s; }
.vector_3 { height: 30vh; transition: all .2s; }
.vector_4 { height: 25vh; transition: all .2s; }
.vector_5 { height: 30vh; transition: all .2s; }
.vector_6 { height: 30vh; transition: all .2s; }

/*! ===================================== Typography ===================================== */

.attachedText {
  display: inline-block;
  white-space: pre-line;
  line-height: 1.4;
}
.xxs-text, .xs-text, .sm-text, .md-text, .md-plus-text, .lg-text, .xl-text, .xxl-text {
  transition: all 0.2s;
}

/*! ===================================== Media Queries ===================================== */

@media only screen and (min-width: 1800px) { /* Extra Large Monitors */
  .xxl-text { font-size: 1.5vw; }
  .customRadioBtn .circle {
    width: 17px;
    height: 17px;
  }
  .customRadioBtn .circle:after {
    width: 9px;
    height: 9px;
  }
  .navButtonsWrapper {
    font-size: 5.2rem;
  }
  .mainLogo {
    height: 70px !important;
  }
  
}
@media only screen and (min-width: 1400px) and (max-width: 2200px) { 
  .xxs-text { font-size: 0.6vw; }
  .xs-text { font-size: 0.7vw; }
  .sm-text { font-size: 0.85vw; }
  .md-text { font-size: 0.96vw; }
  .md-plus-text { font-size: 1.13vw; }
  .lg-text { font-size: 1.4vw; }
  .xl-text { font-size: 1.5vw;}
  .xxl-text { font-size: 1.8vw;}
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) { 
  .xxs-text { font-size: 0.75vw; }
  .xs-text { font-size: 0.91vw; }
  .sm-text { font-size: 0.98vw; }
  .md-text { font-size: 1.135vw;  }
  .md-plus-text { font-size: 1.355vw; }
  .lg-text { font-size: 1.4vw; }
  .xl-text { font-size: 1.5vw; }
  .xxl-text { font-size: 2.1vw; }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px)  { 
  .xxs-text { font-size: 0.75vw; }
  .xs-text { font-size: 0.97vw; }
  .sm-text { font-size: 1.1vw; }
  .md-text { font-size: 1.33vw; }
  .md-plus-text { font-size: 1.4vw; }
  .lg-text { font-size: 1.7vw; }
  .xl-text { font-size: 1.8vw; }
  .xxl-text { font-size: 2.2vw; }
}

@media only screen and (max-width: 1000px) { 
  .xxs-text { font-size: 0.75vw; }
  .xs-text { font-size: 0.96vw; }
  .sm-text { font-size: 1.2vw; }
  .md-text { font-size: 1.4vw; }
  .lg-text { font-size: 1.8vw; }
  .xl-text { font-size: 1.9vw; }
  .xxl-text { font-size: 2.2vw; }

  .customRadioBtn .circle {
    width: 12px;
    height: 12px;
  }
  .customRadioBtn .circle:after {
    width: 6px;
    height: 6px;
  }
  .navButtonsWrapper {
    font-size: 3.2rem;
  }
  .mainLogo {
    height: 40px !important;
  }
}

@media print {
  @page { size: landscape; }

  html, body {
    text-align: right !important;
    direction: rtl !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
/*! ===================================== KeyFrames ===================================== */

.fade-in {
  -webkit-animation: fadeIn 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fadeIn 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes fadeIn {
  from { opacity: 0}
  to { opacity: 1}
}
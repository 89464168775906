
.loaderWrapper {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
}
.titleItems {
    width: 100%;
    display: flex;
    padding: 20px;
}
.title {
    height: 20px;
    margin: 0 10px;
    background-color: rgba(0, 0, 0, 0.1);
}
.title:nth-child(1) { flex-grow: 2 }
.title:nth-child(2), .title:nth-child(3), .title:nth-child(4) { flex-grow: 1 }

.shortTitleItems {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.shortTitle {
    height: 10px;
}
.shortTitle:nth-child(odd) { width: 60%; margin: 10px 10px 0 10px; }
.shortTitle:nth-child(even) { margin: 10px 10px 10px 10px; }
.shortTitle:nth-child(2) { width: 70% !important; }
.shortTitle:nth-child(4) { width: 80% !important; }
.shortTitle:nth-child(6) { width: 90% !important; }

.titlesAnimationOpacity {
    animation: loader 1s infinite ease-in-out;
    -webkit-animation: loader 1s infinite ease-in-out;
}
  
@keyframes loader {
    0% { background-color: rgba(165, 165, 165, 0.1); }
    50% { background-color: rgba(165, 165, 165, 0.3); }
    100% { background-color: rgba(165, 165, 165, 0.1); }
}
    
@-webkit-keyframes loader {
    0% { background-color: rgba(165, 165, 165, 0.1); }
    50% { background-color: rgba(165, 165, 165, 0.3); }
    100% { background-color: rgba(165, 165, 165, 0.1); }
}
.docItem_tr {
    height: 10px;
}
.docItem_tr td:nth-child(1), .docItem_tr td:nth-child(2), .docItem_tr td:nth-child(3)  {
    background-color: white;
    padding: 5px 15px;
}
.docItem_tr:hover td:nth-child(1), .docItem_tr:hover td:nth-child(2), .docItem_tr:hover td:nth-child(3)  {
    background-color: rgb(243, 243, 243);
    transition: all 0.2s;
}
.docItem_tr td:nth-child(4) {
    font-weight: bold;
}
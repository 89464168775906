.chainWrapper {
    position: relative;
    display: grid;
    grid-template-columns: 70% auto;
    grid-template-rows: auto 1fr auto auto;
    height: auto !important;
    max-height: 98%;
    margin-left: 100px;
    transition: all 0.5s;
}
.chainClassifyModalContent {
    direction: rtl !important;
    display: flex;
}
.chainClassifyModalItems {
    display: flex;
    flex-direction: column;
}
.chainClassifyModalContent select {
    padding: 5px 0;
    border: none;
    border: 2px solid #d8d8d8;
    border-radius: 5px;
    background-color: transparent;
    transition: all .2s;
}
.chainClassifyModalContent select option:nth-child(odd) { background-color: #F5F5F7 }

.chainClassifyModalContent select:focus > option:checked { 
    color: white;
    font-weight: bold;
    background: #4a54a0;
}
.chainClassifyModalContent select:hover { border: 2px solid #4a54a0 }
.chainClassifyModalContent select:focus {
    background-color: transparent;
    outline: none;
    box-shadow: none;
}
.classifyTag {
    width: fit-content;
    margin: 0 auto;
    padding: 5px 10px;
    color: white !important;
    text-align: center;
    border-radius: 20px 20px 0 0;
}
.chainHeader {
    grid-column: 1;
}
.chainItemsWrapper {
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    

}
.chainClassifyBtn {
    grid-column: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-top: 5px solid #F5F5F7;
    padding: 10px 0;
    line-height: 1;
}
.chainClassifyBtn:focus { outline: none }
.chainActions {
    grid-column: 1;
}

.overlayTooltip {
    opacity: 0;
    transition: opacity 0.5s;
}
.overlayTooltip .tooltipIcon {
    position: absolute;
    top: 7.5px;
    right: 2px;
}


@media only screen and (min-width: 1800px) { /* Extra Large Monitors */
    .chainWrapper {
        min-width: 320px;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1800px) { /* Large Monitors */
    .chainWrapper {
        min-width: 280px;
    }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px)  { /* Medium Monitors */
    .chainWrapper {
        min-width: 260px;
    }
}
@media only screen and (max-width: 1000px) { /* Small Monitors */
    .chainWrapper {
        min-width: 220px;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .chainWrapper {
        display: -ms-flexbox !important;
        flex-direction: column;
        -ms-flex-direction: column;
        height: 98% !important;
        max-height: 98% !important;
    }
    .chainHeader {
        width: 70% !important;
        flex: 0 1 15%;
    }
    .chainItemsWrapper {
        flex: 1 0 50%;
        display: block;
        width: 100%;
        min-height: 0px !important;
        overflow-y: scroll !important;
        -ms-overflow-y: scroll !important;
    }
    .chainClassifyBtn {
        width: 70%;
        flex: 0 1 15%;
    }
    .chainActions {
        width: 70% !important;
        flex: 0 0 20%;
    }
    .classifyTag {
        width: 40%;
    }
}
.tableWrapper {
    position: absolute;
    height: auto;
    max-height: calc(85% - 20px);
    min-height: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all .2s;
}
.docTable {
    animation: opa 0.4s linear;
}
 .tableHead th {
    position: sticky;
    top: 0;
    background-color: #F5F5F7;
}

.tableHead th:nth-child(1), 
.tableHead th:nth-child(2),
.tableHead th:nth-child(3),
.tableHead th:nth-child(4) {
    padding: 5px 10px 5px 0;
}
.tableHead th:nth-child(1) {
    width: auto;
} 
.tableHead th:nth-child(2),
.tableHead th:nth-child(3) {
    width: 15%
}
.tableHead th:nth-child(4) {
    width: 20%;
    box-shadow: 3px -2px 0px#F5F5F7;
}

@keyframes opa {
    0% { opacity: 0 }
    100% { opacity: 1 }
}
.capsuleWrap {
    width: 100%;
    padding-bottom: 10px;
    color: white;
    user-select: none;
    -ms-user-select: none;
    
}
.capsule {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    border-radius: 25px;
}

@media print {
    .capsule {
        border-radius: 35mm !important;
    }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .capsuleWrap { flex: 0 0 50% }
    .capsule { margin: 0 !important }
}
.pageContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 0;
    margin: 0 auto;
    padding-top: 10px;
    z-index: 998;
}

.pageNavigateTool {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 20px;
}
.chainItemWrapper, .chainItemWrapperEmpt {
    position: relative;
    display: flex;
    display: -ms-flexbox;
    margin-bottom: 20px;
}
.chainItemWrapper { height: 35px }
.chainItemWrapperEmpt { height: 50px }

.chainItemDropZone {
    position: relative;
    width: 70%;
}
.chainItemCapsule {
    position: relative;
    width: 70%;
    
}
.emptDropZone {
    height: 100%;
    border-width: 2px;
    border-color: rgb(216, 216, 216);
    border-style: dashed;
    background-color: rgb(255, 255, 255);
}
.chainItemForm {
    width: 30%;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: center;
    margin-right: 5px;
}
.chainItemArrow {
    position: absolute;
    right: 50%;
    bottom: -20px;
    transform: translateX(50%);
}

@media screen and (min-width: 1800px) {
    .chainItemWrapper { height: 65px }
    .chainItemWrapperEmpt { height: 80px }
}
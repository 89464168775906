/* Css declarations for IE 10 / 11 */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .main-page-wrapper {
        height: 100vh !important;
        display: -ms-flexbox;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .inner-wrapper {
        height: 100%;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }
    .inner-grid {
        display: -ms-flexbox;
        
    }
    .main-content-wrapper {
        height: 100% !important;
        min-height: 100% !important;
        display: -ms-flexbox;
        flex-direction: column;
        -ms-flex-direction: column;
    }
    .main-content {
        min-height: 400px !important;
        display: -ms-flexbox;
        flex-direction: column;
        -ms-flex-direction: column;
    }
    .bottom-content {
        display: -ms-flexbox;
    }
    .right-side-strip {
        display: -ms-flexbox;
        flex-direction: column;
        -ms-flex-direction: column;
    }
    .left-side-strip {
        width: 12.5%;
        position: relative;
    }
    .navButtonsWrapper {
        position: absolute;
        bottom: 0;
    }

    .borderBlue2 { border: 1px solid #4a54a0 !important; }

    .h10 { height: 10% !important; min-height: 10% !important; }
    .h20 { height: 20% !important; min-height: 20% !important; }
    .h30 { height: 30% !important; min-height: 30% !important; }
    .h40 { height: 40% !important; min-height: 40% !important; }
    .h50 { height: 50% !important; min-height: 50% !important; }
    .h60 { height: 60% !important; min-height: 60% !important; }
    .h70 { height: 70% !important; min-height: 70% !important; }
    .h80 { height: 80% !important; min-height: 80% !important; }
    .h90 { height: 90% !important; min-height: 90% !important; }
    .h100 { height: 100% !important; min-height: 100% !important; }
}
.descripWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.2em;
    min-height: 30px;
    margin: 5px;
    border-width: 2px;
    border-color: rgb(216, 216, 216);
    border-style: dashed;
    background-color: rgb(255, 255, 255);
}
.descriptContainer {
    width: 100%;
    flex-grow: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
}
.descriptContainerItem {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    cursor: grabbing;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { .descriptContainerItem { cursor: move !important } }
.questProgressWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50% !important;
    transition: all 0.3s;
}
.emptyProgressItem {
    width: 15px;
    height: 15px;
    background-color: #d8d8d8;
    border-radius: 50%;
    transition: all 0.3s;
}
.emptyProgressItem > span {
    font-size: 1rem;
    font-weight: bold;
    border-radius: 50%;
}
.activeContainer { position: relative }
.activeProgressItem { position: relative }
.activeTag {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 20px;
    color: #ffffff;
    font-size: 0.6rem;
    letter-spacing: 1px;
    border-radius: 16px;
    transform: rotate(-90deg) translateY(350%);
}
.activeTag:before {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    width: 5px;
    height: 5px;
    border: 7px solid;
    border-color: transparent transparent #82BB2D transparent;
    transform: translate(60%, -100%);
}

@media screen and (min-height: 900px) , (min-width: 1800px) {
    .questProgressWrap {
        height: 50% !important;
    }
    .emptyProgressItem {
        width: 25px;
        height: 25px;
    }
    .activeTag {
        width: 110px;
        height: 30px;
        font-size: 0.8rem;
    }
    .activeTag:before {
        width: 8px;
        height: 8px;
        border: 10px solid;
        border-color: transparent transparent #82BB2D transparent;
    }
}